import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

//helper functions imports
import { TransactionData } from '../../helpers/interface';
import { getSvgByCategory } from '../../utils/getSvgByCaregory';
import { checkTransaction } from '../../utils/transactionFunc';
import {
    EventProperites,
    MixPanelCTA,
    MixPanelEvents,
    handlEvents,
} from '../../lib/mixPanel';
import {
    DISPUTE_STATUS,
    disputeStatusBackground,
    getFormattedDate,
    getRefundStatusBasisStatusCode,
    getStatusBasisStatusCode,
} from '../../utils';

//css imports
import styles from './transactioncard.module.scss';

const TransactionCard = ({ transaction }: { transaction: TransactionData }) => {
    const [trans, setTrans] = useState<TransactionData>();
    const router = useRouter();

    useEffect(
        () => setTrans({ ...transaction, loading: false }),
        [transaction]
    );

    const navigateToDetailPage = (transaction: TransactionData) => {
        handlEvents({
            events: MixPanelEvents.billzy_order_transaction,
            module: EventProperites.transcationDetail,
            element: EventProperites.recentTranscation,
            cta: MixPanelCTA.click,
            para1: 'Proceed',
            dataSource: transaction,
        });
        router.push({
            pathname: '/v2/transactionDetail',
            query: {
                bckUrl: 'true',
                goBack: 'true',
                transaction: JSON.stringify(transaction),
            },
        });
    };

    const checkRefundTransactions = (data: TransactionData): boolean => {
        if (data?.refund_status_code_id) return true;
        return false;
    };

    const checkAndPay = (trans: TransactionData): boolean =>
        !trans?.refund_status_code_id &&
        trans?.status_code_id >= 299 &&
        trans?.flow_type === 'BBPS' &&
        trans?.is_partial_payment === true;

    //helper function for returning the classnames on the basis of dispute status
    const returnClassName = (status: string) => {
        switch (status) {
            case DISPUTE_STATUS.UNDER_REVIEW:
                return 'chargeback_status_under_review';
            case DISPUTE_STATUS.CLOSED:
                return 'chargeback_status_closed';
            case DISPUTE_STATUS.OPEN:
                return 'chargeback_status_open';
            default:
                return '';
        }
    };

    //helper function for check and retry functionality
    const checkAndRetry = (trans: TransactionData): boolean =>
        !trans?.refund_status_code_id &&
        [125, 225]?.indexOf(trans?.status_code_id) >= 0;

    //helper function for check and pay and retry functionality
    const checkAndPayAndRetry = async (e, type, enc_o_id) => {
        e.stopPropagation();
        if (trans?.enc_order_id === enc_o_id)
            setTrans({ ...trans, loading: true });
        checkTransaction(type, enc_o_id, router, true, () =>
            setTrans({ ...trans, loading: false })
        );
    };

    return (
        <div className={styles?.transctionItem}>
            <div
                className={styles?.transctionItemGrid}
                onClick={() => navigateToDetailPage(trans)}
            >
                <div className={styles?.transctionIcon}>
                    {getSvgByCategory(trans?.biller_category)}
                </div>
                <div className="transction-detail">
                    <div className={styles?.transctionTitle}>
                        {checkRefundTransactions(trans)
                            ? 'Refund by '
                            : 'Paid to '}
                        {trans?.biller_name || ''}
                    </div>
                    <div style={{ display: 'flex', width: '130%' }}>
                        <span className={styles?.transctionDate}>
                            {getFormattedDate(
                                checkRefundTransactions(trans)
                                    ? trans?.refundedat
                                    : trans?.created
                            )}
                        </span>
                        <span
                            className={`transction-status-${
                                checkRefundTransactions(trans)
                                    ? getRefundStatusBasisStatusCode(
                                          trans?.refund_status_code_id
                                      )?.replace(' ', '-')
                                    : getStatusBasisStatusCode(
                                          trans?.status_code_id
                                      )
                            }`}
                        >
                            {checkRefundTransactions(trans)
                                ? getRefundStatusBasisStatusCode(
                                      trans?.refund_status_code_id
                                  )
                                : getStatusBasisStatusCode(
                                      trans?.status_code_id
                                  )}
                        </span>
                    </div>
                </div>
                <div className={styles?.transctionLeftItem}>
                    <div className="transction-amount">
                        ₹
                        {!trans?.payable_amount
                            ? trans?.amount?.toLocaleString('en-IN')
                            : trans?.payable_amount?.toLocaleString('en-IN')}
                    </div>
                    {checkAndPay(trans) ? (
                        trans?.loading ? (
                            <div className="loader-small"></div>
                        ) : trans?.biller_category ? (
                            <p
                                className="transaction-detail-payagain"
                                onClick={(e) =>
                                    checkAndPayAndRetry(
                                        e,
                                        'pay_balance',
                                        trans?.enc_order_id
                                    )
                                }
                            >
                                Check and Pay Again
                            </p>
                        ) : null
                    ) : checkAndRetry(trans) ? (
                        trans?.loading ? (
                            <div className="loader-small"></div>
                        ) : trans?.biller_category ? (
                            <p
                                className="transaction-detail-payagain"
                                onClick={(e) => {
                                    checkAndPayAndRetry(
                                        e,
                                        'retry_transaction',
                                        trans?.enc_order_id
                                    );
                                }}
                            >
                                Check and Retry
                            </p>
                        ) : null
                    ) : null}
                </div>
            </div>
            <div
                onClick={() => navigateToDetailPage(trans)}
                className={styles?.chargeback_status}
                style={{
                    background: disputeStatusBackground(
                        trans?.disputes?.dispute_status
                    ),
                }}
                hidden={Object.keys(trans?.disputes || {})?.length === 0}
            >
                <span
                    className={
                        styles[
                            `${returnClassName(
                                trans?.disputes?.dispute_status
                            )}_parent`
                        ]
                    }
                >
                    ChargeBack Status
                </span>
                <span
                    className={
                        styles[
                            `${returnClassName(
                                trans?.disputes?.dispute_status
                            )}_child`
                        ]
                    }
                >
                    {trans?.disputes?.dispute_status}
                </span>
            </div>
        </div>
    );
};
export default TransactionCard;
