import {
    sessionStorageKeys,
    formateNonBbpsPaymentMethods,
    formateNetBakingList,
    formateCusomerParams,
    formateVariableAmountInfo,
    generateBillInfoResFromTransctionData,
    generateNonBbpsres,
    menuItemList,
    localStorageKeys,
} from '.';
import { APIS } from '../helpers/constants';
import { get, post } from './apiService';
import { getErrorNotification } from './apiServiceHelpers';
import { setDeeplinkTokenForOrder } from './tokenHandling';

export const fetchMinMaxAmount = async (encOrderId) => {
    try {
        let retainUser = localStorage.getItem(
            localStorageKeys.SECOND_USER_PHONE
        );
        const response = await get({
            endPoint: `${APIS.GET_MIN_MAX}?enc_order_id=${encOrderId}`,
            sendAuthHeader: true,
            backupToken:
                retainUser?.length > 0
                    ? localStorage.getItem(localStorageKeys.SECOND_USER_TOKEN)
                    : null,
            enc_order_id: encOrderId,
            retainUser: retainUser?.length > 0 ? true : false,
        });
        const variableAmountInfo = formateVariableAmountInfo(response);
        sessionStorage.setItem(
            sessionStorageKeys.VARIABLE_AMOUNT_INFO,
            JSON.stringify(variableAmountInfo)
        );
    } catch (error) {}
};

export const setBillInSessionStorage = async (response, encOrderId) => {
    let billInfoRes = {};
    let billerCategory = '';
    const {
        bill_data,
        biller_data,
        flow_type = '',
        net_banking_list,
        payment_modes,
        amount_bifurcation_original,
        trust_name,
        creditline_data,
        company_type,
        amount_type,
    } = response;
    billInfoRes =
        flow_type != 'NON-BBPS'
            ? generateBillInfoResFromTransctionData(bill_data)
            : generateNonBbpsres(bill_data);

    billerCategory = biller_data['category'] || '';

    if (trust_name && trust_name.length)
        sessionStorage.setItem(sessionStorageKeys.TRUST_NAME, trust_name);
    else sessionStorage.removeItem(sessionStorageKeys.TRUST_NAME);
    sessionStorage.setItem(sessionStorageKeys.COMPANY_TYPE, company_type);
    sessionStorage.setItem(sessionStorageKeys.AMOUNT_TYPE, amount_type);
    if (
        amount_bifurcation_original &&
        Object.keys(amount_bifurcation_original)?.length
    )
        sessionStorage.setItem(
            sessionStorageKeys.BIFURCATED_AMOUNT,
            JSON.stringify(amount_bifurcation_original)
        );
    else sessionStorage.removeItem(sessionStorageKeys.BIFURCATED_AMOUNT);

    if (creditline_data && Object.keys(creditline_data)?.length)
        sessionStorage.setItem(
            sessionStorageKeys.CREDITLINE_BIFURCATION,
            JSON.stringify(creditline_data)
        );
    else sessionStorage.removeItem(sessionStorageKeys.CREDITLINE_BIFURCATION);

    sessionStorage.setItem(
        sessionStorageKeys.BILL,
        JSON.stringify(billInfoRes)
    );
    sessionStorage.setItem(
        sessionStorageKeys.ORIGINAL_BILL,
        JSON.stringify(billInfoRes.amount)
    );
    sessionStorage.setItem(sessionStorageKeys.BILLER_CATEGORY, billerCategory);
    sessionStorage.setItem(
        sessionStorageKeys.NET_BANKING_LIST,
        JSON.stringify(formateNetBakingList(net_banking_list))
    );

    sessionStorage.setItem(
        sessionStorageKeys.CUSTOMER_DETAILS,
        JSON.stringify(
            formateCusomerParams(
                bill_data['customerParams'] || [],
                biller_data['customerParams']
            )
        )
    );

    const formattedPaymentsModeList =
        formateNonBbpsPaymentMethods(payment_modes);
    sessionStorage.setItem(
        sessionStorageKeys.ALLOWED_PAYMENT_MODES,
        JSON.stringify(formattedPaymentsModeList)
    );

    if (flow_type !== 'NON-BBPS') {
        const { billerName, billerId } = biller_data;
        sessionStorage.setItem(
            sessionStorageKeys.SELECTED_BILLER,
            JSON.stringify({
                label: billerName,
                id: billerId,
            })
        );
    } else {
        const { BillerName, BillerId } = billInfoRes;
        sessionStorage.setItem(
            sessionStorageKeys.SELECTED_BILLER,
            JSON.stringify({
                label: BillerName,
                id: BillerId,
            })
        );
    }

    if (flow_type === 'NON-BBPS') {
        sessionStorage.setItem(sessionStorageKeys.FLOW_TYPE, flow_type);
    } else {
        sessionStorage.setItem(sessionStorageKeys.FLOW_TYPE, 'BBPS');
    }
    await fetchMinMaxAmount(encOrderId);
};

export const checkTransaction = async (
    endPoint,
    encOrderId,
    router,
    fromTransctionHistory = false,
    cb
) => {
    let apiUrl = `/${endPoint}?enc_order_id=${encOrderId}`;
    if (endPoint === 'retry_transaction')
        apiUrl = `/${endPoint}?enc_order_id=${encOrderId}&b2c=${fromTransctionHistory}`;
    let retainUser = localStorage.getItem(localStorageKeys.SECOND_USER_PHONE);
    try {
        let token = '';
        let enc_o_id = null;
        if (localStorage.getItem(localStorageKeys.ACCESS_TOKEN)) {
            token = localStorage.getItem(localStorageKeys.ACCESS_TOKEN);
        } else {
            token = await JSON.parse(sessionStorage.getItem(encOrderId))
                ?.deeplinkToken;
            enc_o_id = encOrderId;
        }
        //remove company_id before fetching bill
        sessionStorage.removeItem(sessionStorageKeys.COMPANY_ID);
        const response = await post({
            endPoint: apiUrl,
            responseType: 'json',
            sendAuthHeader: true,
            backupToken:
                retainUser?.length > 0
                    ? localStorage.getItem(localStorageKeys.SECOND_USER_TOKEN)
                    : null,
            enc_order_id: enc_o_id,
            retainUser: retainUser?.length > 0 ? true : false,
        });
        //setCompanyid that will be used for vpa validation
        sessionStorage.setItem(
            sessionStorageKeys.COMPANY_ID,
            response?.company_id
        );
        await setDeeplinkTokenForOrder(
            retainUser?.length > 0
                ? localStorage.getItem(localStorageKeys.SECOND_USER_TOKEN)
                : token,
            response?.bill_data?.enc_refId || response?.enc_order_id,
            false
        );

        await setBillInSessionStorage(
            response,
            response?.bill_data?.enc_refId || response?.enc_order_id
        );

        const category = response?.biller_data?.category;
        let menuIndex = 0;
        menuItemList.forEach((menu, index) => {
            if (menu.apiKey === category) {
                menuIndex = index;
            }
        });
        router.push(
            `/v2/billInfo?itemIndex=${menuIndex}&goBack=true&bckUrl=true`
        );
    } catch (error) {
        getErrorNotification(error);
        cb && cb();
    }
};
