export const setDeeplinkTokenForOrder = async (
    token,
    enc_order_id,
    isDeeplink
) => {
    let dataObj = {
        deeplinkToken: token,
        isDeeplink,
        isPopupDisplayed: false,
    };

    sessionStorage.setItem(enc_order_id, JSON.stringify(dataObj));
};
